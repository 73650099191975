import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="796 796 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M796 834.5v122.955L866.218 896l-69.987-61.253c-.087-.076-.15-.168-.231-.247zM916.703 903.947l-6.547 5.729c-4.035 3.53-9.097 5.297-14.156 5.297-5.061 0-10.121-1.767-14.155-5.297l-6.544-5.728-71.712 62.763h184.826l-71.712-62.764zM988.53 825.289H803.47c.216.144.438.278.639.454l85.614 74.932c3.578 3.131 8.977 3.132 12.555-.002l85.613-74.93c.202-.176.422-.31.639-.454zM995.77 834.746L925.783 896 996 957.455V834.5c-.08.079-.145.171-.23.246z"></path>
    </svg>
  );
}

export default Icon;
