.skills {
  margin-top: calc(var(--margin) * 8);
  display: flex;
  width: 100%;
  gap: calc(var(--gap) * 12);
  justify-content: center;
  align-items: flex-start;
}

.imgCon {
  max-height: 100%;
  max-width: 300px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  width: 100%;
  height: auto;
}

.skillsTextCon {
  width: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 7);
}

@media (max-width: 700px) {
  .skills {
    flex-direction: column;
    align-items: center;
  }

  .skillsTextCon {
    gap: calc(var(--gap) * 6);
    width: 100%;
  }

  .imgCon {
    width: 100%;
  }
}
