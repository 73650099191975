.heroBg {
  background: conic-gradient(
      from 0deg,
      #0b0b0b,
      #131313,
      #222222,
      #3d3d3d,
      #222222,
      #131313,
      #0b0b0b,
      #000000,
      #131313,
      #222222,
      #3d3d3d,
      #464646,
      #3d3d3d,
      #222222,
      #131313,
      #0b0b0b,
      #131313,
      #222222,
      #3d3d3d,
      #666666,
      #464646,
      #3d3d3d,
      #222222,
      #131313,
      #0b0b0b
    )
    1;
  background-size: 400% 400%;
  animation: gradientHero 10s ease infinite;
  animation-timing-function: ease-in-out;
  height: 100%;
  width: 100%;
  mask-image: url("../../images/background.svg");
  position: absolute;
  inset: 0;
}

.hero * {
  z-index: 3;
}

@keyframes gradientHero {
  0% {
    background-position: 0% 100%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}

.hero {
  color: var(--primary-light);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  align-items: flex-start;
  justify-content: center;
  padding: calc(var(--padding) * 13);
  padding-top: calc(var(--padding) * 20);
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  min-height: 100vh;
  width: 100%;
  margin-bottom: calc(var(--margin) * 5);
}

@supports (-webkit-touch-callout: none) {
  .hero {
    min-height: var(--hero-height);
  }
}

.iconCon {
  height: fit-content;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
  animation-name: arrowLoop;
}

.icon {
  width: 40px;
  height: 40px;
  fill: var(--primary-light);
}

@keyframes arrowLoop {
  0% {
    bottom: 25px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 25px;
  }
}

@media (hover: hover) {
  .arrowLink:hover {
    cursor: pointer;
  }
}

@media (min-width: 1100px) {
  .hero {
    gap: calc(var(--gap) * 3);
  }
}

@media (min-width: 1400px) {
  .icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: calc(var(--padding) * 15) calc(var(--padding) * 5);
  }
}
