.cardCon {
  width: 80%;
  min-width: 500px;
  max-width: 900px;
  display: flex;
  min-height: 320px;
  background-color: var(--primary-light);
  box-shadow: 0 0 10px var(--text-bg);
  border-radius: var(--border-radius);
  position: relative;
}

.projectName {
  width: 50px;
  padding: var(--padding);
  background-color: var(--primary-dark);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  text-transform: uppercase;
  color: var(--primary-light);
  transform: rotate(-90deg);
  letter-spacing: 10px;
  font-family: var(--font-primary);
  font-size: 1.5rem;
  text-indent: 0.5em;
}

.projectBody {
  width: 100%;
  background-color: var(--primary-light);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  border-radius: var(--border-radius);
}

.projectDesc {
  width: 60%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: calc(var(--padding) * 2);
  padding-left: calc(var(--padding) * 5);
  gap: calc(var(--gap) * 2);
}

.projectImg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  background-position: center;
  background-size: cover;
  display: flex;
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  transition: all 0.5s ease-in-out;
  opacity: 0.8;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.btnCon {
  position: absolute;
  inset: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 0;
}

.btn {
  border: 1px solid var(--primary-dark);
  background-color: var(--primary-light);
  color: var(--primary-dark);
  opacity: 0;
}

@keyframes btnReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (hover: hover) {
  .cardCon:hover {
    cursor: pointer;
  }

  .cardCon:hover > .btnCon {
    height: 100%;
  }

  .cardCon:hover .btn {
    animation-name: btnReveal;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .cardCon:hover .projectImg {
    width: 85%;
    opacity: 1;
  }

  .cardCon:hover .projectDesc {
    opacity: 0.2;
  }
}

@media (max-width: 700px) {
  .cardCon {
    width: 90%;
    min-width: 265px;
    max-width: 300px;
    flex-direction: column;
    min-height: 600px;
  }

  .projectName {
    width: 100%;
    border-top-right-radius: var(--border-radius);
    border-bottom-left-radius: 0;
    height: 50px;
  }

  .name {
    transform: rotate(0deg);
  }

  .projectBody {
    height: 100%;
    flex-grow: 1;
  }

  .projectDesc {
    width: 100%;
    height: fit-content;
    max-height: 70%;
    padding: calc(var(--padding) * 4);
  }

  .projectImg {
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 70%;
    clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 0.5s ease-in-out;
    opacity: 0.8;
    border-top-right-radius: 0;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  @media (hover: hover) {
    .cardCon:hover .projectImg {
      width: 100%;
      top: 20%;
    }
  }
}

@media (max-width: 480px) {
  .cardCon {
    min-height: 620px;
  }
}
