.header {
  position: fixed;
  background-color: var(--primary-dark);
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 15px var(--primary-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.navList {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--padding) * 2) calc(var(--padding) * 4);
}

.initials {
  font-family: var(--font-primary);
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--primary-light);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linksCon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--gap) * 5);
}

.link {
  color: var(--primary-light);
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.btn {
  margin: 0;
  font-size: 1.1rem;
}

.btnHamburger {
  display: none;
}

.iconHamburger {
  display: none;
}

.btnMobile {
  display: none;
}

@media (hover: hover) {
  .link:hover {
    font-style: italic;
  }

  .link:focus-visible {
    font-style: italic;
  }

  .btnHamburger:hover {
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .btnHamburger {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .iconHamburger {
    display: flex;
    width: 30px;
    height: 30px;
    fill: var(--primary-light);
  }

  .nav {
    display: none;
  }

  .btn {
    display: none;
  }

  .btnMobile {
    display: block;
  }

  .linksCon {
    flex-direction: column;
  }

  .mobileMenu {
    border-top: 1px solid var(--primary-light);
    border-bottom: 1px solid var(--primary-light);
    background-color: var(--primary-dark);
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap) * 5);
    top: 60px;
    left: 0;
    right: 0;
    padding: calc(var(--padding) * 5);
  }
}
