.formCon {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: calc(var(--padding) * 10) calc(var(--padding) * 2);
  min-height: 50vh;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  min-height: 485px;
  height: 100%;
  gap: calc(var(--gap) * 4);
  flex-direction: column;
  background-color: var(--primary-light);
  padding: calc(var(--padding) * 6);
}

.senderInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.label {
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-dark);
  text-transform: uppercase;
  gap: var(--gap);
}

.senderInfoLabel {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.textLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  height: 50px;
  background-color: var(--text-bg);
  border: 1px solid var(--primary-dark);
  border-radius: var(--border-radius);
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-dark);
  padding: var(--padding);
}

.input:focus {
  outline: none;
}

.textArea {
  resize: none;
  height: 120px;
}

.btnSubmit {
  margin: 0;
  border: 1px solid var(--primary-dark);
  background-color: var(--primary-light);
  color: var(--primary-dark);
  box-shadow: 5px 5px 0px var(--primary-dark);
  width: 50%;
  min-width: 150px;
  max-width: 230px;
  position: relative;
  min-height: 45px;
}

.btnInProgress {
  pointer-events: none;
  animation-duration: 1.4s;
  animation-name: inProgressScale;
  animation-timing-function: ease-in-out;
}

@keyframes inProgressScale {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.btnFill {
  position: absolute;
  inset: 0;
  background-color: var(--text-bg);
  width: 0;
}

.btnFillProgress {
  animation-duration: 0.7s;
  animation-name: inProgressFill;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.btnText {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes inProgressFill {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@media (hover: hover) {
  .btnSubmit:hover {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: none;
  }
  .btnSubmit:focus-visible {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: none;
  }
}

@media (max-width: 700px) {
  .senderInfo {
    flex-direction: column;
  }

  .senderInfoLabel {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .form {
    padding: calc(var(--padding) * 4);
  }
}
