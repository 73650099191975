.title {
  font-family: var(--font-primary);
  font-weight: 400;
  text-transform: uppercase;
}

.titlePrimary {
  color: var(--primary-dark);
  font-size: 12vw;
  margin: calc(var(--margin) * 2) 0;
}

.titleSecondary {
  color: var(--primary-dark);
  font-size: 2rem;
  margin-bottom: var(--margin);
}

.paragraph {
  font-family: var(--font-secondary);
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--primary-dark);
  line-height: 25px;
}

.titleHero {
  color: var(--primary-light);
  font-size: 12vw;
}

.titleHero::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

.paragraphHero {
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--primary-light);
  line-height: 35px;
}

.paragraphHero::selection {
  color: var(--primary-dark);
  background: var(--primary-light);
}

.section {
  padding: calc(var(--padding) * 5) calc(var(--padding) * 13);
  margin: calc(var(--margin) * 5) 0;
  min-height: 50vh;
  width: 100%;
  max-width: 1200px;
}

.sectionText {
  width: 90%;
  max-width: 900px;
  display: block;
}

.skillTags {
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--primary-dark);
  border-radius: var(--border-radius);
  padding: var(--padding) calc(var(--padding) * 2);
  background-color: var(--text-bg);
  box-shadow: 5px 5px 0px var(--primary-dark);
  transition: all 0.3s ease-in-out;
}

.tagsCon {
  display: flex;
  gap: calc(var(--gap) * 2);
  flex-wrap: wrap;
}

.btn {
  font-family: var(--font-primary);
  font-size: 1.5rem;
  font-weight: 400;
  border-radius: var(--border-radius);
  padding: var(--padding) calc(var(--padding) * 2);
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  margin: calc(var(--margin) * 3) 0;
  width: fit-content;
  height: fit-content;
  display: block;
}

.btnLight {
  border: 2px solid var(--primary-light);
  background-color: var(--primary-light);
  color: var(--primary-dark);
}

.btnDark {
  border: 2px solid var(--primary-dark);
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

.frame {
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-dark);
}

.frameRight {
  border-top-right-radius: var(--border-radius-accent);
  box-shadow: -10px 10px 0px var(--primary-dark);
}

.frameLeft {
  border-top-left-radius: var(--border-radius-accent);
  box-shadow: 10px 10px 0px var(--primary-dark);
}

.links {
  color: var(--primary-dark);
  transition: all 0.3s ease-in-out;
  text-decoration: underline;
}

.notFound {
  min-height: 100vh;
  margin: 0;
}

@media (hover: hover) {
  .btn:hover {
    cursor: pointer;
  }

  .btnLight:hover {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: 5px 5px 0px var(--primary-light);
  }

  .btnLight:focus-visible {
    background-color: var(--primary-dark);
    color: var(--primary-light);
    box-shadow: 5px 5px 0px var(--primary-light);
  }

  .btnDark:hover {
    background-color: var(--primary-light);
    color: var(--primary-dark);
    box-shadow: 5px 5px 0px var(--primary-dark);
  }

  .btnDark:focus-visible {
    background-color: var(--primary-light);
    color: var(--primary-dark);
    box-shadow: 5px 5px 0px var(--primary-dark);
  }

  .links:hover {
    text-decoration-line: underline;
    text-decoration-style: double;
  }

  .links:focus-visible {
    text-decoration-line: underline;
    text-decoration-style: double;
  }

  .skillTags:hover {
    cursor: default;
    color: var(--text-bg);
    background-color: var(--primary-dark);
    box-shadow: none;
  }
}

@media (min-width: 1100px) {
  .titleHero {
    font-size: 9rem;
  }

  .titlePrimary {
    font-size: 9rem;
  }
}

@media (max-width: 480px) {
  .titleHero {
    font-size: 17vw;
  }

  .paragraphHero {
    font-size: 1.2rem;
  }

  .paragraph {
    font-size: 1rem;
  }

  .titlePrimary {
    font-size: 3rem;
  }

  .titleSecondary {
    font-size: 1.8rem;
  }

  .sectionText {
    width: 95%;
  }

  .section {
    padding: calc(var(--padding) * 5);
    margin: calc(var(--margin) * 2) 0;
    padding-top: calc(var(--padding) * 10);
  }

  .btn {
    font-size: 1.2rem;
  }
}
